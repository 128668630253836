/* * * * * * * * * * */
/* Content aligment  */
/* * * * * * * * * * */

  .mix-alignments(@type) {

    .center-@{type} { // -> Use this
      text-align: center;
    }
    .justify-@{type} { // -> Use this
      text-align: justify;
    }
    .left-@{type} { // -> Use this
      text-align: left;
    }
    .right-@{type} { // -> Use this
      text-align: right;
    }
  }

  /* * * * * * * * */
  /* Media querys */
  /* * * * * * * * */

    @media (min-width: @screen-x-min) {
      .mix-alignments(x);
    }

    @media (min-width: @screen-s-max) {
      .mix-alignments(s);
    }

    @media (min-width: @screen-m-max) {
      .mix-alignments(m);
    }

    @media (min-width: @screen-l-max) {
      .mix-alignments(l);
    }

  /* * * * * * * * */

/* * * * * * * * * * * */
/* Grid                */
/* * * * * * * * * * * */

  .mix-make-column(@type) {

    .scol-generate(@n, @i: 1) when (@i =< @n) {

      .scol-@{type}-@{i} { // -> Use this
        width: ( @shido-grid * @i - @width);

        &.scol--full { // -> Use this
           width: ( @shido-grid * @i ) * 1%;
        }
      }
      .scol-generate(@n, (@i + 1));
    }

    .scol-fit-generate(@n, @i: 1) when (@i =< @n) {

      .scol-@{type}-@{i}--fit { // -> Use this
        width: ( (100% / @i) - @width );

        &.scol--full{ // -> Use this
           width: ( (100% / @i) * 1% );
        }
      }
      .scol-fit-generate(@n, (@i + 1));
    }

    //--

    .scol-generate(@columns);
    .scol-fit-generate(@columns);
  }

  .mix-set-column-all {

    [class*="scol-x"],
    [class*="scol-s"],
    [class*="scol-m"],
    [class*="scol-l"] {
      margin: @sides-margin;
      display: inline-block;
      vertical-align: middle;
      width: ( @shido-grid * @columns - @width);

      & > .line {
        margin: -@margin-bt -@margin-lr;
        width: (100% + (@margin-lr * 2));

        &.line--full{
          margin: 0px;
          width: 100%;
        }
      }

      &.scol--full {
        margin: 0px;
        margin-bottom: 0px;
        margin-left: 0px;
        margin-top: 0px;
        margin-right: 0px;
      }
    }
  }

  .mix-set-column-all;

  /** Remove margin of scol **/

  .mix-remove-margins(@type) {

    .remove-mrg(@n, @i: 1) when (@i =< @n) {

      // Default

      .scol-@{type}--preserve-mrg-bottom { // -> Use this
        margin-bottom: @margin-bt;
      }

      .scol-@{type}--preserve-mrg-left { // -> Use this
        margin-left: @margin-lr;
      }

      .scol-@{type}--preserve-mrg-top { // -> Use this
        margin-top: @margin-bt;
      }

      .scol-@{type}--preserve-mrg-right { // -> Use this
        margin-right: @margin-lr;
      }

      // Both

      .scol-@{type}--preserve-mrg-lr { // -> Use this
        margin-left: @margin-lr;
        margin-right: @margin-lr;
      }

      .scol-@{type}--preserve-mrg-bt { // -> Use this
        margin-bottom: @margin-bt;
        margin-top: @margin-bt;
      }

      // All

      .scol-@{type}--preserve-mrg-all { // -> Use this
        margin: @sides-margin;
        margin-bottom: @margin-bt;
        margin-left: @margin-lr;
        margin-top: @margin-bt;
        margin-right: @margin-lr;
      }

      /* * * * * * * * * * * * * * * * * * * * * */

      .scol-@{type}--no-mrg-bottom { // -> Use this
        margin-bottom: 0px;
      }

      .scol-@{type}--no-mrg-left { // -> Use this
        margin-left: 0px;
      }

      .scol-@{type}--no-mrg-top { // -> Use this
        margin-top: 0px;
      }

      .scol-@{type}--no-mrg-right { // -> Use this
        margin-right: 0px;
      }

      // Both

      .scol-@{type}--no-mrg-lr { // -> Use this
        margin-left: 0px;
        margin-right: 0px;
      }

      .scol-@{type}--no-mrg-bt { // -> Use this
        margin-bottom: 0px;
        margin-top: 0px;
      }

      // All

      .scol-@{type}--no-mrg-all { // -> Use this
        margin: 0px;
        margin-bottom: 0px;
        margin-left: 0px;
        margin-top: 0px;
        margin-right: 0px;
      }

      .remove-mrg(@n, (@i + 1));
    }

    //--

    .remove-mrg(4);
  }

  /* * * * * * * * */
  /* Media querys */
  /* * * * * * * * */

    @media (min-width: @screen-x-min) {
      .mix-make-column(x);
      .mix-remove-margins(x);
    }

    @media (min-width: @screen-s-max) {
      .mix-make-column(s);
      .mix-remove-margins(s);
    }

    @media (min-width: @screen-m-max) {
      .mix-make-column(m);
      .mix-remove-margins(m);
    }

    @media (min-width: @screen-l-max) {
      .mix-make-column(l);
      .mix-remove-margins(l);
    }

  /* * * * * * * * */

/* * * * * * * * * * * */
/* Hiding and showing  */
/* * * * * * * * * * * */

  .mix-make-hiding-showing(@type) {

    .not-@{type} { // -> Use this
      display: none !important;
    }

    .stay-@{type} { // -> Use this
      display: block !important;
    }

    .stay-@{type}--inline { // -> Use this
      display: inline-block !important;
    }
  }

  /* * * * * * * * */
  /* Media querys */
  /* * * * * * * * */

    @media (min-width: @screen-x-min) {
      .mix-make-hiding-showing(x);
    }

    @media (min-width: @screen-s-max) {
      .mix-make-hiding-showing(s);
    }

    @media (min-width: @screen-m-max) {
      .mix-make-hiding-showing(m);
    }

    @media (min-width: @screen-l-max) {
      .mix-make-hiding-showing(l);
    }

  /* * * * * * * * */

/* * * * * * * * * */
/* Margin          */
/* * * * * * * * * */

  .mix-margin(@type) {

    .mrg-side(@n, @i: 0) when (@i =< @n) {

      @count: @i * 10;

      .mrg-@{type}-all-@{count} { // -> Use this
        margin-bottom: @count * 1px;
        margin-left: @count * 1px;
        margin-right: @count * 1px;
        margin-top: @count * 1px;
      }

      .mrg-@{type}-bt-@{count} { // -> Use this
        margin-bottom: @count * 1px;
        margin-top: @count * 1px;
      }

      .mrg-@{type}-lr-@{count} { // -> Use this
        margin-left: @count * 1px;
        margin-right: @count * 1px;
      }

      .mrg-@{type}-bottom-@{count} { // -> Use this
        margin-bottom: @count * 1px;
      }

      .mrg-@{type}-left-@{count} { // -> Use this
        margin-left: @count * 1px;
      }

      .mrg-@{type}-right-@{count} { // -> Use this
        margin-right: @count * 1px;
      }

      .mrg-@{type}-top-@{count} { // -> Use this
        margin-top: @count * 1px;
      }

      //--

      .mrg-negative-@{type}-all-@{count} { // -> Use this
        margin-bottom: -@count * 1px;
        margin-left: -@count * 1px;
        margin-right: -@count * 1px;
        margin-top: -@count * 1px;
      }

      .mrg-negative-@{type}-bt-@{count} { // -> Use this
        margin-bottom: -@count * 1px;
        margin-top: -@count * 1px;
      }

      .mrg-negative-@{type}-lr-@{count} { // -> Use this
        margin-left: -@count * 1px;
        margin-right: -@count * 1px;
      }

      .mrg-negative-@{type}-bottom-@{count} { // -> Use this
        margin-bottom: -@count * 1px;
      }

      .mrg-negative-@{type}-left-@{count} { // -> Use this
        margin-left: -@count * 1px;
      }

      .mrg-negative-@{type}-right-@{count} { // -> Use this
        margin-right: -@count * 1px;
      }

      .mrg-negative-@{type}-top-@{count} { // -> Use this
        margin-top: -@count * 1px;
      }

      .mrg-side(@n, (@i + .5));
    }

    //--

    .mrg-side(@margin-side);
  }

  /* * * * * * * * */
  /* Media querys */
  /* * * * * * * * */

    @media (min-width: @screen-x-min) {
      .mix-margin(x);
    }

    @media (min-width: @screen-s-max) {
      .mix-margin(s);
    }

    @media (min-width: @screen-m-max) {
      .mix-margin(m);
    }

    @media (min-width: @screen-l-max) {
      .mix-margin(l);
    }

  /* * * * * * * * */

/* * * * * * * * * * * */
/* Vertical alignment  */
/* * * * * * * * * * * */

  .mix-vertical-alignment(@type) {

    .bottom-@{type} { // -> Use this
      vertical-align: bottom;
    }
    .middle-@{type} { // -> Use this
      vertical-align: middle;
    }
    .top-@{type} { // -> Use this
      vertical-align: top;
    }
  }

  /* * * * * * * * */
  /* Media querys */
  /* * * * * * * * */

    @media (min-width: @screen-x-min) {
      .mix-vertical-alignment(x);
    }

    @media (min-width: @screen-s-max) {
      .mix-vertical-alignment(s);
    }

    @media (min-width: @screen-m-max) {
      .mix-vertical-alignment(m);
    }

    @media (min-width: @screen-l-max) {
      .mix-vertical-alignment(l);
    }

  /* * * * * * * * */

/* * * * * * * * * */
/* Padding         */
/* * * * * * * * * */

  .mix-padding(@type) {

    .pad-side(@n, @i: 0) when (@i =< @n) {

      @count: @i * 10;

      .pad-@{type}-all-@{count} { // -> Use this
        padding-bottom: @count * 1px;
        padding-left: @count * 1px;
        padding-right: @count * 1px;
        padding-top: @count * 1px;
      }

      .pad-@{type}-bt-@{count} { // -> Use this
        padding-bottom: @count * 1px;
        padding-top: @count * 1px;
      }

      .pad-@{type}-lr-@{count} { // -> Use this
        padding-left: @count * 1px;
        padding-right: @count * 1px;
      }

      .pad-@{type}-bottom-@{count} { // -> Use this
        padding-bottom: @count * 1px;
      }

      .pad-@{type}-left-@{count} { // -> Use this
        padding-left: @count * 1px;
      }

      .pad-@{type}-right-@{count} { // -> Use this
        padding-right: @count * 1px;
      }

      .pad-@{type}-top-@{count} { // -> Use this
        padding-top: @count * 1px;
      }

      .pad-side(@n, (@i + .5));
    }

    //--

    .pad-side(@padding-side);
  }

  /* * * * * * * * */
  /* Media querys */
  /* * * * * * * * */

    @media (min-width: @screen-x-min) {
      .mix-padding(x);
    }

    @media (min-width: @screen-s-max) {
      .mix-padding(s);
    }

    @media (min-width: @screen-m-max) {
      .mix-padding(m);
    }

    @media (min-width: @screen-l-max) {
      .mix-padding(l);
    }

  /* * * * * * * * */

/* * * * * * * * * */
/* Fonts           */
/* * * * * * * * * */

  /* Example:
    If @body-font-size = 16px
    then .fs-x-16 = 16px */

  /* Available fonts
    From .fs-x-6 to the .fs-x-80 Increasing in pairs */

  .mix-responsive-fonts(@type) {

    .font-size-generate(@n, @i: 6) when (@i =< @n) {

      .fs-@{type}-@{i} {
        font-size: (@i/@body-font-size) * 1rem;
      }
      .font-size-generate(@n, (@i + 2));
    }

    .font-size-generate(80);
  }

  /* * * * * * * * */
  /* Media querys */
  /* * * * * * * * */

    @media (min-width: @screen-x-min) {
      .mix-responsive-fonts(x);
    }

    @media (min-width: @screen-s-max) {
      .mix-responsive-fonts(s);
    }

    @media (min-width: @screen-m-max) {
      .mix-responsive-fonts(m);
    }

    @media (min-width: @screen-l-max) {
      .mix-responsive-fonts(l);
    }

  /* * * * * * * * */

/* * * * * * * * * */
/* Pulling         */
/* * * * * * * * * */

// Coming soon

/* * * * * * * * * */
/* Pushing         */
/* * * * * * * * * */

// Coming soon

/* * * * * * * * * */
