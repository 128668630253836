/* * * * * * * * * */
/* Animations      */
/* * * * * * * * * */

.animate-line-background {

  &:hover {

    &:before {

      @keyframes line_background {

        0% {
          width: 0%;
        }

        50% {
          height: 2px;
          width: 100%;
        }

        100% {
          height: 100%;
          width: 100%;
        }

      }

      animation-name: line_background;
      animation-duration: .5s;
      animation-timing-function: ease-in-out;
      animation-delay: 0s;
      animation-iteration-count: 1;
      animation-direction: normal;
      animation-fill-mode: forwards;

    }

    & a {

      @keyframes line_background_ico {

        0% {
          color: @c-black;
        }

        100% {
          color: @c-white;
        }

      }

      animation-name: line_background_ico;
      animation-duration: .5s;
      animation-timing-function: ease-in-out;
      animation-delay: .1s;
      animation-iteration-count: 1;
      animation-direction: normal;
      animation-fill-mode: forwards;

    }
  }
}


.animate-nav-item {

  .animate-nav-item--loop(@loops) {

    .loop(@loops, @i: 1) when (@i =< @loops) {

      &:nth-of-type(@{i}){

        @name: ~"animate-nav-item_@{i}";
        @delay_time: @i / 20;
        @delay: ~"@{delay_time}s";


        @keyframes @name {

          0% {
            left: -20px;
            opacity: 0;
            transform: scale(1.4);
          }

          100% {
            left: 0px;
            opacity: 1;
            transform: scale(1);
          }

        }

        animation-name: @name;
        animation-duration: .5s;
        animation-timing-function: ease-in-out;
        animation-delay: @delay;
        animation-iteration-count: 1;
        animation-direction: normal;
        animation-fill-mode: forwards;
      }
      //--------
      .loop(@loops, (@i + 1));
    }
    //--
    .loop(@loops);
  }


  .animate-nav-item--loop(15);
}
