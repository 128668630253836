/* * * * * * * * * */
/* About file     */
/* * * * * * * * * */

.catering-about {

  & .logo {

    & img {
      max-width: 200px;
      width: 100%;
    }
  }
}
