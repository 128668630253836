/* * * * * * * * * */
/* Footer styles   */
/* * * * * * * * * */

.footer {
  background: @c-grey;
  color: @c-black-light;
  padding: 10px 20px;

  & a {
    color: @c-black-light;

    &:hover {
      color: @c-grey-dark;
    }
  }

  & .dinkbit {

    & img {
      max-width: 80px;
      width: 100%;
    }
  }
}
