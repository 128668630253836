/* * * * * * * * * * * * * */
/* Dropdown Styles         */
/* * * * * * * * * * * * * */

.dropdown {

  & ul {
    margin: 0px;
    padding: 0px;

    & li {
      display: block;
      list-style: none;
      margin: 0px;

      & .show {
        background: @c-grey-light;
        padding: 20px;

        & .logo {
          padding-top: 30px;

          & img {
            max-width: 280px;
            width: 100%;
          }
        }

        & .open-dropdown {
          border: solid 2px transparent;
          color: @c-black-light;
          display: inline-block;
          .fs-40;
          height: 50px;
          margin-bottom: 20px;
          margin-top: 20px;
          padding: 5px 20px;
          position: relative;
          .select-disabled;
          width: 50px;

          &:before,
          &:after {
            background: @c-black-light;
            content: "";
            left: 50%;
            .mix-transition;
            position: absolute;
            top: 50%;
            -ms-transform: translate(-50%, -50%) rotate(0deg);
            transform: translate(-50%, -50%) rotate(0deg);
            width: 80px;
          }

          &:before {
            height: 4px;
            width: 30px;
          }

          &:after {
            height: 30px;
            width: 4px;
          }

          &.active,
          &:hover {
            border-color: @c-grey;
            color: @c-grey-dark;
          }

          &.active {

            &:before {
              -ms-transform: translate(-50%, -50%) rotate(-180deg);
              transform: translate(-50%, -50%) rotate(-180deg);
            }

            &:after {
              -ms-transform: translate(-50%, -50%) rotate(90deg);
              transform: translate(-50%, -50%) rotate(90deg);
            }
          }
        }
      }

      & .hide {
        display: none;
        padding: 40px 20px;
      }
    }
  }
}
