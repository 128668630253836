/* * * * * * * * * */
/* Buttons styles  */
/* * * * * * * * * */

.btn {
  border: solid 1px @c-grey-dark;
  background: none;
  color: @c-grey-dark;
  cursor: pointer;
  display: inline-block;
  .fs-16;
  .mix-transition;
  padding: 15px 25px;

  &.btn--full {
    width: 100%;
  }

  &.btn--black {
    .mix-btn--color(
    @c-black,
    @c-black,
    @c-white,

    @c-black-light,
    @c-black-light,
    @c-grey-dark,
    );
  }

  &.btn--white-o {
    .mix-btn--color(
    transparent,
    @c-white,
    @c-white,

    @c-white,
    transparent,
    @c-black-light,
    );
  }

  &.btn--white-static-o {
    .mix-btn--color(
    transparent,
    @c-white,
    @c-white,

    transparent,
    @c-white,
    @c-white,
    );
  }
}
