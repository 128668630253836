/* * * * * * * * * */
/* Images file     */
/* * * * * * * * * */

.about-about {

  & .logo {

    & img {
      max-width: 200px;
      width: 100%;
    }
  }
}

.about-signatures {

  & .signature {

    & img {
      max-width: 200px;
      width: 100%;
    }
  }
}
