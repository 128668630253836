/* * * * * * * * * */
/* Logo file       */
/* * * * * * * * * */

.job-board-form {

  & .background {
    background-size: cover;
    background-position: center;
    min-height: 200px;
  }
}
