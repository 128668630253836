/* * * * * * * * * * * * * */
/* Simple Header Styles    */
/* * * * * * * * * * * * * */

.simple-header {
  background-size: cover;
  background-position: center;
  max-height: 480px;
  position: relative;

  &.post {
    max-height: initial;

    & img {
      width: 100%;
    }

    & .content {
      background: fade(@c-black, 70%);
      bottom: 0px;
      color: @c-white;
      left: 0px;
      .mix-transition(all, .3s);
      opacity: 1;
      padding: 20px;
      position: absolute;
      width: 100%;

      & p {
        .txt-uppercase;
        width: 100%;
      }
    }
  }
}
