/* * * * * * * * * */
/* Grid            */
/* * * * * * * * * */

  .smain-container { // -> Use this
    width: 100%;
  }

  .scontainer { // -> Use this
    margin: 0 auto;
    max-width: @container;
    padding: 0px 10px;
    width: 100%;

    &.scontainer--full-width { // -> Use this
      max-width: 100%;
    }

    &.scontainer--no-padding { // -> Use this
      padding: 0px;
    }

    &.scontainer--relative { // -> Use this
      position: relative;
    }

    &.scontainer--full-height { // -> Use this
      height: 100%;
    }

    &.scontainer--half {
      margin: 0;
      max-width: @container / 2;
    }

  }

  .line { // -> Use this
    font-size: 0px;
    letter-spacing: 0px;
    margin: 0 auto;
    width: 100%;
    word-spacing: 0px;

    & > * {
      font-size: @clear-space;
      letter-spacing: initial;
      word-spacing: initial;
    }
  }

  .scol-break{ // -> Use this
    width: 100%;
  }

  /* * * * * * * * * */
  /* Modifiers       */
  /* * * * * * * * * */

  .scol--height-full { // -> Use this
    height: 100%;
  }

  .scol--relative {
    position: relative; // -> Use this
  }

/* * * * * * * * * * * */
