/* * * * * * * * * */
/* Custom colors   */
/* * * * * * * * * */

@c-black-light: #4A4A4A;
@c-black:       @c-grey-1000;
@c-white:       @c-grey-0;

@c-grey-light:  #E3E3E3;
@c-grey:        #D8D8D8;
@c-grey-dark:   #959595;


/* * * * * * * * * */
/* Colors Classes  */
/* * * * * * * * * */

.bg-grey-light {
  background-color: @c-grey-light;
}

.bg-grey {
  background-color: @c-grey;
}

.bg-grey-dark {
  background-color: @c-grey-dark;
}
