/* * * * * * * * * */
/* Mosaic styles   */
/* * * * * * * * * */

.mosaic {
  background: @c-grey;
  height: 100%;
  left: 0px;
  overflow: hidden;
  position: absolute;
  top: 0px;
  width: 100%;
  z-index: 0;

  &:after {
    background: fade(@c-grey, 50%);
    content: "";
    height: 100%;
    left: 0px;
    position: absolute;
    top: 0px;
    width: 100%;
  }

  & div {
    height: 100%;
  }

  & .tile {
    background-color: @c-grey;
    background-position: center;
    background-size: cover;
    filter: grayscale(0%);
    height: 100/3%;
    min-height: 120px;
    .mix-transition(all, .5s, ease, width);
    opacity: 1;
    position: relative;

    &.effect {
      filter: grayscale(80%);
      opacity: .60;
    }
  }
}

@media (max-width: @screen-m-min) {

  .mosaic {

    & .tile {
      height: 100/4%;
    }
  }
}

