/* * * * * * * * * */
/* Mixins styles   */
/* * * * * * * * * */

/* * * * * * * * * */
/* Fonts Size      */
/* * * * * * * * * */

  /* Example:
    If @body-font-size = 16px
    then .fs-16 = 16px */

  /* Available fonts
    From .fs-6 to the .fs-80 Increasing in pairs */

  .mix-make-font-size(@available_size) {

    .font-size-generate(@n, @i: @min-font-size) when (@i =< @n) {

      .fs-@{i} {
        font-size: (@i/@body-font-size) * 1rem;
      }
      .font-size-generate(@n, (@i + @increase_font));
    }

    .font-size-generate(@available_size);
  }

/* * * * * * * * * */
/* Transitions     */
/* * * * * * * * * */

  .mix-transition(
      @property: all,
      @time: .5s,
      @dir: ease,
      @cancel: outline
    ){
    transition: @property @time @dir, @cancel .000001s;
  }

/* * * * * * * * * */
/* Buttons         */
/* * * * * * * * * */

  .mix-btn--color(
    @background:        transparent,
    @border:            transparent,
    @color:             @c-grey-1000,

    @background-hover:  transparent,
    @border-hover:      transparent,
    @color-hover:       @c-grey-1000,
  ){
    background: @background;
    border:     solid 2px @border;
    color:      @color;

    &:hover{
      background: darken(@background-hover, 10%);
      border:     solid 2px darken(@border-hover, 10%);
      color:      @color-hover;
    }
  }

/* * * * * * * * * */
