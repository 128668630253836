/* * * * * * * * * */
/* Logo file       */
/* * * * * * * * * */

.home-logo {
  height: 100%;
  padding: 30px;
  position: relative;
  text-align: center;
  z-index: 1;

  & img {
    max-width: 500px;
    width: 100%;
  }
}

@media (max-width: @screen-s-min) {

  .home-logo {

    & img {
      max-width: 300px;
    }
  }
}
