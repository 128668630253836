/* * * * * * * * * */
/* DOM styles      */
/* * * * * * * * * */

  * {
    outline: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    &:focus {
      outline: none;
    }
  }

  html,body {
      color: @body-font-color;
      font-size: @body-font-size;
      background-color: @body-background-color;
      font-family: @base-font;
      font-weight: @base-font-weight;

      height:100%;
      margin:0 auto;
      padding:0px;
      position:relative;
      width:100%;
  }

  a {
      color: @main-link-color;
      cursor: pointer;
      text-decoration: none;
      .mix-transition;

      &.block {
        display: block;
      }

      &.inline {
        display: inline-block;
      }

      &:active,
      &:hover,
      &:focus {
        color: @main-link-color-hover;
        text-decoration: none;
      }
  }

  hr {
      border: none;
      border-top: solid 2px @separator-border-color;
      margin: 0px;
      padding: 0px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
      color: @heading-color;
      display: inline-block;
      font-family: @heading-font;
      font-weight: @base-font-weight;
      margin: 0px;
      position: relative;
      white-space: pre-line;
  }

  img {
    border: none;
    display: inline-block;
    outline: none;
    vertical-align: middle;

    &.img-liquid {
      max-width: 100%;
    }
  }

  p {
    margin: 0px;
    padding: 5px 0px;
    white-space: pre-line;
  }

/* * * * * * * * * */
/* Helpers         */
/* * * * * * * * * */

  .p-relative {
    position: relative;
  }

  .select-disabled {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .table {
    display: table;
    height: 100%;
    width: 100%;

    & .cell {
      display: table-cell;
      height: 100%;
      vertical-align: middle;
      width: 100%;

      &.bottom {
        vertical-align: bottom;
      }

      &.top {
        vertical-align: top;
      }
    }
  }

  .txt-light {
    font-weight: @base-font-weight-light !important;
  }

  .txt-normal {
    font-weight: @base-font-weight;
  }

  .txt-bold {
    font-weight: @base-font-weight-bold;
  }

  .txt-uppercase {
    text-transform: uppercase;
  }

  .txt-uppercase-none {
    text-transform: normal;
  }

  .txt-capitalize {
    text-transform: capitalize;
  }

  .txt-first-uppercase {

    &:first-letter {
      text-transform: uppercase;
    }
  }

/* * * * * * * * * */
/* noscript        */
/* * * * * * * * * */

  noscript {

    & iframe {
      height: 0 !important;
      width: 0 !important;
      line-height: 0 !important;
      font-size: 0 !important;
      margin-top: -13px;
      float: left;
    }
  }

/* * * * * * * * * */
/* no-js: ;        */
/* * * * * * * * * */

  .no-js {

    body {
      padding-top: 52px;
      @media screen and (max-width: 767px) {
        padding-top: 72px;
      }
    }

    .noJS {
      display: block;
      width: 100%;

      &.alert {
        position: absolute;
        top: 0;
        z-index: 100000;
      }
    }
  }
