/* * * * * * * * * */
/* Cards file      */
/* * * * * * * * * */

.blog-posts{

  & .blog-card{
    cursor: pointer;
    overflow: hidden;
    position: relative;

    & .background {
      background-position: center;
      background-size: cover;
      filter: grayscale(80%);
      height: 100%;
      .mix-transition(all, .3s);
      left: 0px;
      position: absolute;
      top: 0px;
      -ms-transform: scale(1);
      transform: scale(1);
      width: 100%;
    }

    & .title {
      background: fade(@c-black, 70%);
      bottom: 0px;
      color: @c-white;
      left: 0px;
      .mix-transition(all, .3s);
      opacity: 1;
      padding: 20px;
      position: absolute;
      width: 100%;

      & p {
        border-bottom: solid 2px fade(@c-grey-dark, 50%);
        padding-bottom: 10px;
        .txt-uppercase;
        width: 100%;
      }
    }

    &:hover{

      & .background {
        filter: grayscale(0%);;
        -ms-transform: scale(1.2);
        transform: scale(1.2);
      }

      & .title {
        opacity: .2;
      }
    }
  }
}
