/* * * * * * * * * */
/* Messages styles */
/* * * * * * * * * */

.custom-messages{
  background: fade(@c-black, 65%);
  height: 100%;
  left: 0%;
  position: fixed;
  top: 0%;
  width: 100%;
  z-index: 9999;

  & .alert {
    background: @c-grey-0;
    box-shadow: 0px 1px 5px -2px @c-black;
    left: 50%;
    max-height: 90%;
    max-width: 500px;
    overflow: auto;
    position: absolute;
    top: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 90%;

    & .header {
    }

    & .message {
      background: @c-grey-100;

      & .description {
        border: solid 2px @c-grey-200;
        padding: 10px;
      }

      & .list {

        & table {
          border-collapse: collapse;
          width: 100%;

          & tr {

            &:nth-of-type(odd) {
              background: @c-grey-200;
            }

            & td {
              padding: 10px;
            }
          }
        }
      }
    }

    //--

    &.danger {

      & .header {
        background: @c-red-400;
        color: @c-grey-0;
      }

      & .message {

        & .description {
          border-color: @c-red-400;
          color: @c-red-400;
        }

        & .list {

          & table {

            & tr {

              & td {
                color: @c-red-400;
              }
            }
          }
        }
      }
    }

    &.success {

      & .header {
        background: @c-green-400;
        color: @c-grey-0;
      }

      & .message {

        & .description {
          border-color: @c-green-400;
          color: @c-green-400;
        }

        & .list {

          & table {

            & tr {

              & td {
                color: @c-green-400;
              }
            }
          }
        }
      }
    }
  }

  & .close {
    background: @c-red-400;
    box-shadow: -1px 1px 5px -2px @c-black;
    border-radius: 50%;
    color: @c-grey-0;
    height: 30px;
    position: absolute;
    right: 10px;
    top: 15px;
    width: 30px;
    z-index: 9999;

    & i {
      .fs-18;
      left: 50%;
      position: absolute;
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      top: 50%;
    }

    &:hover {
      background: darken(@c-red-400, 10%);
    }
  }
}
