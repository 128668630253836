/* * * * * * * * * */
/* Cards file      */
/* * * * * * * * * */

.restaurants-logos {

  & .restaurant-card {
    background-color: @c-grey;
    overflow: hidden;
    position: relative;

    & .background {
      background-position: center;
      background-size: cover;
      height: 100%;
      filter: blur(0px);
      .mix-transition(all, .3s);
      position: relative;
      -ms-transform: scale(1);
      transform: scale(1);
      width: 100%;

      &:before {
        background: fade(@c-black, 30%);
        content: "";
        height: 100%;
        left: 0px;
        .mix-transition(all, .3s);
        position: absolute;
        top: 0px;
        width: 100%;
      }
    }

    & .logo {
      left: 0%;
      .mix-transition(all, .3s);
      padding: 10px;
      position: absolute;
      top: 50%;
      -ms-transform: translate(0%, -50%);
      transform: translate(0%, -50%);
      width: 100%;

      & img {
        max-width: 200px;
        .mix-transition(all, .3s);
        width: 100%;
      }
    }

    & .action {
      left: 50%;
      .mix-transition(all, .3s);
      position: absolute;
      top: -50%;
      -ms-transform: translate(-50%, 0%);
      transform: translate(-50%, 0%);
    }

    &:hover {

      & .background {
        filter: blur(3px);
        -ms-transform: scale(1.1);
        transform: scale(1.1);

        &:before {
          background: fade(@c-black, 60%);
        }
      }

      & .logo {
        top: -50%;
        -ms-transform: translate(0%, 0%);
        transform: translate(0%, 0%);

        & img {
          width: 60%;
        }
      }

      & .action {
        top: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      }
    }
  }
}
