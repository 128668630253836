/* * * * * * * * * * * * */
/* Mods Styles           */
/* * * * * * * * * * * * */

h1,
h2,
h3,
h4,
h5,
h6 {
  color: @c-black-light;
  .txt-uppercase;
}
