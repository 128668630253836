/* * * * * * * * * */
/* Cards file      */
/* * * * * * * * * */

.team-people {

  & .person-card {
    overflow: hidden;
    padding: 10px;
    position: relative;

    & .background {
      background-position: center;
      background-size: cover;
      filter: grayscale(0%);
      height: 100%;
      .mix-transition(all, .35s);
      position: relative;
      width: 100%;

      &:before {
        background: fade(@c-black, 0%);
        content: "";
        height: 100%;
        left: 0px;
        .mix-transition(all, .35s);
        position: absolute;
        top: 0px;
        width: 100%;
      }
    }

    & .action {
      color: @c-white;
      left: 50%;
      padding: 20px;
      position: absolute;
      top: 50%;
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      width: 100%;

      & hr {
        border-top: solid 1px @c-white;
        margin: 10px auto;
        max-width: 150px;
        .mix-transition(all, .35s);
        width: 0%;
      }

      & span {
        .fs-6;
        .mix-transition(all, .35s);
        margin-top: 20px;
      }

      & .phase-1,
      & .phase-2 {
        .mix-transition(all, .35s);
        opacity: 0;
      }

      & .phase-1 {
        margin-bottom: 100px;
      }
    }

    &:hover {

      & .background {
        filter: grayscale(80%);

         &:before {
          background: fade(@c-black, 60%);
        }
      }

      & .action {

        & hr {
          width: 100%;
        }

        & span {
          .fs-60;
        }

        & .phase-1,
        & .phase-2 {
          opacity: 1;
        }

        & .phase-1 {
          margin-bottom: 0px;
        }
      }
    }
  }
}
