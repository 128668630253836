/* * * * * * * * * * * */
/* Team Sidebar file   */
/* * * * * * * * * * * */

.team-sidebar {
  background: fade(@c-black, 90%);
  color: @c-white;
  height: 100%;
  max-width: 600px;
  .mix-transition(all, .3s);
  position: fixed;
  right: -600px;
  top: 0px;
  width: 100%;

  & .close {
    background: fade(@c-black, 50%);
    color: @c-white;
    .fs-26;
    display: inline-block;
    padding: 10px 20px;
    position: relative;
    z-index: 1;

    &:hover {
      color: @c-grey-dark;
    }
  }

  & .content {
    color: @c-white;
    height: 100%;
    left: 0px;
    overflow: auto;
    padding: 20px;
    position: absolute;
    top: 0px;
    width: 100%;
    z-index: 0;

    & hr {
      border-top: solid 1px @c-white;
      margin: 10px auto;
      max-width: 80px;
      width: 100%;
    }
  }

  &.active {
    right: 0px;
  }
}


@media (max-width: @screen-l-min) {

  .team-sidebar {
    max-width: 450px;
    right: -450px;
  }
}

@media (max-width: @screen-m-min) {

  .team-sidebar {
    max-width: 350px;
    right: -350px;
  }
}

@media (max-width: @screen-s-min) {

  .team-sidebar {
    max-width: 280px;
    right: -280px;
  }
}

