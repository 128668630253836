/* * * * * * * * * */
/* Nav styles      */
/* * * * * * * * * */

.main-menu {
  background: @c-grey;
  color: @c-black;
  left: 0px;
  padding: 0px 20px;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 999;

  & .open-nav {
    display: block;
    height: 30px;
    position: relative;
    width: 35px;

    & span {
      background: @c-black;
      border-radius: 5px;
      height: 6px;
      left: 50%;
      .mix-transition(all, .2s);
      position: absolute;
      width: 100%;

      &:nth-of-type(1) {
        top: 0%;
      }

      &:nth-of-type(2) {
        margin-top: -3px;
        top: 50%;
      }

      &:nth-of-type(3) {
        bottom: 0%;
      }

      &:nth-of-type(1),
      &:nth-of-type(2),
      &:nth-of-type(3) {
        margin-left: -50%;
      }

      &:nth-of-type(4),
      &:nth-of-type(5) {
        margin-left: -3px;
        margin-top: -3px;
        top: 50%;
        width: 6px;
      }

      &:nth-of-type(4) {
        transform: rotate(45deg);
      }

      &:nth-of-type(5) {
        transform: rotate(-45deg);
      }

    }

    /*&:hover {

      & span {

        &:nth-of-type(1),
        &:nth-of-type(2),
        &:nth-of-type(3) {
          margin-left: -3px;
          width: 6px;
        }

        &:nth-of-type(2) {
          left: 35px;
        }
      }
    }*/

    &.active {

      & span {

        &:nth-of-type(1),
        &:nth-of-type(2),
        &:nth-of-type(3) {
          background: @c-black-light;
          margin-left: -3px;
          width: 6px;
        }

        &:nth-of-type(2) {
          left: 35px;
        }

        &:nth-of-type(4),
        &:nth-of-type(5) {
          margin-left: -50%;
          width: 100%;
        }
      }
    }
  }

  & .logo {

    & img {
      max-width: 110px;
      width: 100%;
    }
  }

  & .social {

    & ul {
      .line;
      margin: 0px;
      padding: 0px;

      & li {
        .animate-line-background; // Include Hover.
        display: inline-block;
        list-style: none;
        margin: 0px;
        padding: 5px;
        position: relative;

        & a {
          color: @c-black;
          display: block;
          padding: 5px;
          position: relative;
          z-index: 1;
        }

        &:before {
          background: @c-black;
          bottom: 0px;
          content: "";
          height: 2px;
          left: 0px;
          .mix-transition;
          position: absolute;
          width: 0px;
          z-index: 0;
        }
      }
    }
  }

  & nav {
    background: fade(@c-grey, 90%);
    display: none;
    height: 100%;
    left: 0px;
    position: fixed;
    width: 100%;
    z-index: 999;

    &:before {
      border: none;
      border-left: dotted 2px @c-grey-dark;
      content: "";
      height: 100%;
      left: 55px;
      position: absolute;
      top: 0px;
      width: 1px;
    }

    & .nav-content {
      height: 100%;
      .mix-transition;
      overflow: auto;

      & ul {
        margin: 0px;
        max-width: 500px;
        padding: 0px;

        & li {
          .animate-nav-item;
          display: block;
          list-style: none;
          margin: 0px;
          opacity: 0;
          .mix-transition;
          padding: 0px;
          position: relative;

          &:before {
            color: @c-grey-dark;
            content: "\f111";
            font: normal normal normal 6px/1 FontAwesome;
            left: -15px;
            margin-top: -3px;
            position: absolute;
            top: 50%;
          }

          & a {
            color: @c-grey-dark;
            display: block;
            padding: 15px 20px;
            .txt-uppercase;
          }

          &.active,
          &:hover {

            & a {
              color: @c-black-light;
            }
          }
        }
      }
    }
  }

  &.glass {
    background: transparent;
    border-bottom: solid 2px @c-grey-dark;
  }
}

@media (max-width: @screen-x-max) {

  .main-menu {

    & nav {

      &:before {
        left: 25px;
      }

      & .nav-content {

        & ul {

          & li {

            & a {
              .fs-14;
              padding: 10px 20px;
            }

            &:before {
              left: -10px;
            }
          }
        }
      }
    }
  }
}
