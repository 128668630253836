/* * * * * * * * * */
/* Forms styles    */
/* * * * * * * * * */

form {
  //overflow: hidden;

  & .input-field {
    display: inline-block;
    margin-bottom: 10px;
    margin-top: 10px;
    position: relative;

    & input:not([type=checkbox]):not([type=radio]),
    & select,
    & textarea {
      appearance: none;
      background: transparent;
      border: none;
      display: inline-block;
      .fs-16;
      .mix-transition(all, .3s);
      padding: 20px;
      position: relative;
      text-align: center;
      vertical-align: middle;
      width: 100%;
      z-index: 2;

      & ~ .extra {
        height: 100%;
        left: 0px;
        position: absolute;
        top: 0px;
        width: 100%;

        &:before,
        &:after {
          border: solid 2px @c-black-lighter;
          content: "";
          height: 100%;
          .mix-transition(all, .3s);
          position: absolute;
          top: -2px;
          width: 50%;
          z-index: 0;
        }

        &:before {
          border-right: none;
          left: -2px;
        }

        &:after {
          border-left: none;
          right: -2px;
        }
      }

      & ~ label {
        color: @c-grey-dark;
        display: block;
        left: 50%;
        min-width: 100%;
        .mix-transition(all, .2s);
        opacity: 1;
        overflow: hidden;
        padding: 20px;
        position: absolute;
        text-align: center;
        text-overflow: ellipsis;
        top: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        white-space: nowrap;
        width: 100%;
      }

      &.error {

        & ~ .extra {

          &:before,
          &:after {
            border-color: @c-red-400;
          }
        }

        & ~ label {
          color: @c-red-400;
        }
      }

      &:hover,
      &:focus {

        & ~ .extra {

          &:before,
          &:after {
            border-color: @c-black-light;
          }
        }
      }

      &.active,
      &:focus {

        & ~ .extra {

          &:before {
            left: -10px;
          }

          &:after {
            right: -10px;
          }
        }

        & ~ label {
          opacity: 0;
        }
      }
    }

    & select {
      opacity: 0;

      &:focus {

        & ~ label {
          opacity: 1;
        }
      }

      &.active{

        & ~ label {
          color: @c-black;
          opacity: 1;
        }
      }
    }

    & textarea {
      min-height: 150px;
      resize: none;
    }

    &.file {

      & input[type='file'] {
        visibility: hidden;

        & ~ label {
          cursor: pointer;
        }

        &.active,
        &:focus {

          & ~ label {
            color: @c-black;
            opacity: 1;
          }
        }
      }
    }

    &.checkbox,
    &.radio {
      display: inline-block;

      input[type='checkbox'],
      input[type='radio'] {
        display: none;

        & ~ label {
          cursor: pointer;
          display: block;
          padding: 20px;
          padding-left: 45px;
          position: relative;

          &:before,
          &:after {
            content: "";
            .mix-transition(all, .3s);
            position: absolute;
            top: 50%;
          }

          &:before {
            border: solid 2px @c-black-lighter;
            height: 20px;
            left: 10px;
            margin-top: -10px;
            width: 20px;
          }

          &:after {
            background: @c-black-lighter;
            height: 6px;
            left: 19px;
            margin-top: -1px;
            -ms-transform: scale(1);
            transform: scale(1);
            width: 6px;
          }
        }

        &.error {

          & ~ label {

            &:before {
              border: solid 2px @c-red-400;
            }

            &:after {
              background: @c-red-400;
            }
          }
        }

        &:checked {

          & ~ label {

            &:before {
              border: solid 2px @c-black-light;
            }

            &:after {
              background: @c-black-light;
              -ms-transform: scale(2.6);
              transform: scale(2.6);
            }
          }
        }
      }
    }
  }
}
